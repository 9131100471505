<template>

  <div>
    <a-col :span="20" class="contact-point">
      <span style="font-weight:bold;">{{ name }}</span>
      <span style="font-weight:bold;">{{ part }}</span>
    </a-col>
  </div>

</template>

<script>

export default {
  name: 'FeaturePoint',
  props: ['name', 'part', 'color'],
}
</script>

<style>
.contact-point {
  margin-top: 10px;
}
@media (max-width: 767px) {
  .contact-point {
    padding: 0 10px;
  }
}
h3 {
  font-weight: bold
}

h4 {
  font-size: 1.125rem;
  color: grey
}


</style>
