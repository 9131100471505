<template>

  <a-row class="feature-point">
    <a-col :md="3" :xs="3">
      <div class="tick" :class="[color]">
          <Icon name="tick" style="text-align: center"/>
      </div>
    </a-col>
    <a-col :md="21" :xs="21">
      <h4 >{{ part }}</h4>
    </a-col>
  </a-row>

</template>

<script>
import Icon from "../../components/Icon.vue"

export default {
  name: 'FeaturePoint',
  props: ['part', 'color'],
  components: {
    Icon
  }
}
</script>

<style>
.feature-point {
  margin-top: 10px
}
@media (max-width: 768px) {
  .feature-point {
    padding: 10px
  }
}

.tick {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
h3 {
  font-weight: bold;
}

h4 {
  font-size: 1.125rem;
  color: grey;
}

@media (max-width: 767px) {
  .tick {
    width: 30px;
    height: 30px;
  }
}


div.first{
  border-radius: 20px;
  background-color:rgba(57, 162, 219,var(--tw-bg-opacity))
}

div.second{
  background-color:rgba(0, 194, 203,var(--tw-bg-opacity))
}
div.third{
  background-color:rgba(155, 114, 170,var(--tw-bg-opacity))
}
div.forth{
  background-color: rgba(174,42,63, 1);
}
div.fifth{
  background-color: rgba(216,98,43, 1);
}
div.sixth{
  background-color: rgba(76,161,84, 1);
}


</style>
