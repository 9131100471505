


































































































































































































import {Component, Vue} from "vue-property-decorator";
import FeatureComponent from "@/views/Feature/FeatureComponent.vue";
import FeaturePoints from "@/views/Feature/FeaturePoints.vue";
import Contact from "@/views/Feature/Contact.vue";

@Component({
  components: {
    FeatureComponent,
    FeaturePoints,
    Contact
  }
})

export default class Features extends Vue {
  logout(e) {
    e.preventDefault();
    localStorage.clear();
    this.$router.push('/auth')
  }

}

