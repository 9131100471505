








export default {
  name: 'FeatureComponent',
  props:['heading','details'],

}
