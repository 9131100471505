






















































import { Component, Vue } from "vue-property-decorator";
import Icon from "../components/Icon.vue";
@Component({
  components: {
    Icon
  }
})
export default class Profile extends Vue {
  profile = JSON.parse(localStorage.getItem("profile") || "{}")
  get role():string {
    let name:string;
    switch(this.profile.scope) {
      case 'GSA':
        name = 'Global Super Admin';
        break;
      case 'BA':
        name = 'Business Admin';
        break;
      case 'LA':
        name = 'Location Admin';
        break;
      default:
        break;
    }
    return name;
  }
}
